import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Carousel} from "react-bootstrap";
import Contact from "./contact";

const GameCarousel = () => {
    const content = [
        {
            title : "la course aux pièces d’or",
            content : ["Attrapez le plus de pièces d’or,<br/> sans perdre de vies !"],
            link : '/game/attrape-piece',
            enable : true,
            event : "The race for gold coins"
        },
        {
            title : "lE PLONGEON ARTISTIQUE",
            content : ["Enchaînez les figures et marquez<br/>un maximum de points !"],
            link : '/game/plongeon-artistique',
            enable : true,
            event : "Artistic diving"
        },
        {
            title : "Les obstacles sous-marins",
            content : ["Allez le plus loin possible en<br/>évitant les obstacles !"],
            link : "/game/flappy-baleine",
            enable : false,
            event : "Underwater Obstacles"
        }
    ];

    let items = [];
    let items_desktop = [];

    content.forEach( (item, key) => {
        items.push(
            <Carousel.Item key={"GameItem" + key}>

                <div className={`carousel_game_item ${item.enable?'':'disabled'}`} id={"carousel_game_item" + (key + 1)}>

                    <div>
                        {item.enable &&
                            <>
                                <div className={"title"}>{item.title}</div>
                                <div className={"content"}>
                                    {item.content.map(text => {
                                        return (<p dangerouslySetInnerHTML={{__html: text}}/>)
                                    })}
                                </div>
                            </>
                        }
                    </div>
                    {item.enable && <a href={item.link} className={"cta white"} onClick={() => window.mixpanelhandler.track("Play button click",{"Result count" : 1, "Game Name" : item.event})}>Jouer</a> }
                    {!item.enable && <a href={"#"} className={"cta white disabled"}>Jouer</a> }

                </div>

            </Carousel.Item>
        )

        items_desktop.push(
            <div className={`game_item ${item.enable?'':'disabled'}`} key={"GameItem" + key} id={"game_item" + (key + 1)}>
                <div>
                    {item.enable &&
                        <>
                            <div className={"title"}>{item.title}</div>
                            <div className={"content"}>
                                {item.content.map(text => {
                                    return (<p dangerouslySetInnerHTML={{__html: text}}/>)
                                })}
                            </div>
                        </>
                    }
                </div>
                {item.enable && <Link to={item.link} className={"cta white"} onClick={() => window.mixpanelhandler.track("Play button click",{"Result count" : 1, "Game Name" : item.event})}>Jouer</Link> }
                {!item.enable && <a href={"#"} className={"cta white disabled"}>Jouer</a> }
            </div>
        )
    })

    return (
        <>
            <div id={"game_carousel_wrapper"} className={"d-lg-none"}>
                <Carousel controls={true} interval={null} indicators={false}>
                    {items}
                </Carousel>
            </div>

            <div id={"game_wrapper"} className={"d-lg-flex d-none"}>
                {items_desktop}
            </div>
        </>
    );
}

export {GameCarousel};

const Home = () => {

    const createCarouselHowto = () => {

        const content = [
            "un produit La Baleine <a href='/file/Produits%20éligibles.pdf' target='_blank'>éligible</a> <br/><b>entre le 01/01/2024</b> et <br class='d-lg-none'/><b>le 31/12/2024</b> inclus.",
            "et relevez les défis sportifs <br/>de La Baleine.<span>(Jeu sans obligation d'achat.)</span>",
            "le formulaire de participation et <br class='d-lg-none'/>téléchargez votre preuve d'achat <a href='/je-participe' target='_blank'>ici</a><br/><b>avant le 07/01/2025</b> à 23h59.",
            "<b>sous 7 jours ouvrés</b> un e-mail avec <br class='d-lg-none'/>votre code cadeau <br class='d-none d-lg-block'/><a href='/academie-du-gout' target='_blank'>Académie <br class='d-lg-none'/>du Goût</a>.<span>(Sous réserve de validité de votre<br class='d-lg-none'/> participation.)</span>",
            "de <b>2 mois de cours de cuisine <br class='d-lg-none'/>en ligne</b> offerts !<span>(Le code cadeau est à activer sous un délai<br class='d-lg-none'/> de 3 mois à réception.)</span>"
        ]

        let items = [];
        for(let i=0; i<5; i++){

            items.push(
                <Carousel.Item key={"HowToItem" + i}>
                    <div className={`carousel_howto_item`} id={"carousel_howto_item" + (i + 1)} dangerouslySetInnerHTML={{ __html : content[i]}}></div>
                </Carousel.Item>
            )
        }

        return (
            <div id={"howto_carousel_wrapper"}>
                <Carousel controls={true} interval={null} indicators={true} onSlid={(index) => window.mixpanelhandler.track("Page Loaded",{"Result count" : 1, "Page Name" : "Participation _Steps for participation Slider_"+(parseInt(index)+1)})} >
                    {items}
                </Carousel>
            </div>
        );
    }

    return (
        <>
            <div id={"homeStrate1"} className={"strate first"}>
                <Link className={"cta white picto small"} to={"/#homeStrate3"}>jouer aux jeux</Link>

                <div className={"cta_wrapper"}>
                    <Link className={"cta orange picto"} to={"/je-participe"} onClick={() => window.mixpanelhandler.track("Link Click",{"Result count" : 1, "Link Landing Page" : "On home Participation Form Page Link"})}>Participer à l’offre</Link>
                    <Link className={"cta white picto small"} to={"/academie-du-gout"} onClick={() => window.mixpanelhandler.track("Link Click",{"Result count" : 1, "Link Landing Page" : "On home Link to La Baleine website"})}>En savoir plus</Link>
                </div>

                <p>
                    Offre nominative, cumulable, limitée à une participation par personne <br className={"d-lg-none"}/>
                    (même nom, même prénom) et par adresse e-mail, valable pour l’achat d’un produit <br/>
                    La Baleine éligible du 01/01/2024 au 31/12/2024 inclus. L’offre donne droit <br className={"d-lg-none"}/>
                    à un abonnement de 2 mois de cours de cuisine en ligne offert valable <br className={"d-lg-none"}/>
                    sur <a href={"https://www.academiedugout.fr/"} target={"_blank"}>www.academiedugout.fr</a>. <br className={"d-none d-lg-block"}/>Voir <a href="/file/Termes%20et%20conditions.pdf" target={"_blank"}>Termes et conditions de l’offre</a>. <br className={"d-lg-none"}/>
                    Visuels non contractuels.
                </p>
            </div>

            <div id={"homeStrate2"} className={"strate"}>
                <a href={"https://www.labaleine.fr/la-baleine-a-90-ans/?utm_source=referral&utm_medium=banner&utm_campaign=tlc"} target={"_blank"} onClick={() => window.mixpanelhandler.track("Link Click",{"Result count" : 1, "Link Landing Page" : "Participation Product Link on La Baleine website"})}>plus d’informations sur www.labaleine.fr</a>
            </div>

            <div id={"homeStrate3"} className={"strate"}>
                <h2>relevez les défis sportifs <span>de la baleine !</span></h2>
                {/*createCarouselGame()*/}
                <GameCarousel/>
            </div>

            <div id={"homeStrate4"} className={"strate"}>
                <h2 className={"line"}>
                    <div className={"anchor"} id={"comment_participer"}/>
                    comment participer
                </h2>
                {createCarouselHowto()}

                <div className={"text-center"}>
                    <Link to={"/je-participe"} className={"cta orange picto"}>Participer à l'offre</Link>
                </div>
            </div>

            <Contact/>
        </>
    );
};


export default Home;
